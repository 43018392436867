<template>
    <v-container fluid>
        <v-row>
            <v-col 
                cols="6"
                lg="10"
                md="8"
                sm="6"
            >
                <h3>Carrinho</h3>
            </v-col>
            <v-col 
                cols="6"
                lg="2"
                md="4"
                sm="6"
                style="text-align: right;"
            >
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip 
                            v-bind="attrs"
                            v-on="on"
                            link
                            small
                            @click="confirmarLimpezaCarrinho()"
                        >
                            <v-icon small left>mdi-cart-remove</v-icon> Limpar Carrinho
                        </v-chip>
                    </template>
                    <span>Limpar Carrinho</span>
                </v-tooltip>
            </v-col>
        </v-row>

        <v-row 
            v-if="isUsuarioInterno || temDistribuidorPai"
        >
            <v-col 
                v-if="isUsuarioInterno"
                cols="12"
                lg="3"
                md="3"
                sm="12"
            >
                <label>Tipo de Cliente</label>
                <v-combobox
                    v-model="tipoClienteSelected"
                    :items="listTipoCliente"
                    :rules=[validations.required]
                    item-text="descricao"
                    item-value="id"
                    clearable
                    outlined
                    @change="recarregarCarrinho(); carregarDescontos(); verificaKeyAccount();"
                ></v-combobox>
            </v-col>
            <v-col 
                v-if="isTipoDistribuidor || temDistribuidorPai"
                cols="12"
                :lg="temDistribuidorPai ? 12 : 6"
                :md="temDistribuidorPai ? 12 : 5"
                sm="12"
            >
                <label>Distribuidor</label>
                <v-combobox
                    v-model="distribuidorSelected"
                    :items="listDistribuidor"
                    item-text="nameAndCnpj"
                    item-value="id"
                    :rules=[validations.required]
                    clearable
                    single-line
                    outlined
                    :loading="loadingDistribuidor"
                    @change="consultarCampanhas(); setTipoPedido();"
                ></v-combobox>                
            </v-col>
            <v-col 
                v-if="tipoClienteSelected != null && tipoClienteSelected.id != 2"
                cols="12"
                lg="6"
                md="5"
                sm="12"
            >
                <label>Cliente</label>
                <div style="display: flex">
                    <v-combobox
                        v-model="clienteSelected"
                        :items="listClientesFiltered"
                        item-text="nameAndCnpj"
                        item-value="id"
                        :rules=[validations.required]
                        clearable
                        single-line
                        outlined
                        :loading="loadingClientes"
                        @change="getListProdutosKeyAccount(); setTipoPedido();"
                    ></v-combobox>
                    
                    <v-tooltip 
                        top
                        v-if="mostrarBotaoNovoCliente"
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                style="margin-top: 10px; margin-left: 10px;"
                                v-if="mostrarBotaoNovoCliente"
                                v-on="on"
                                outlined
                                x-small
                                fab
                                dense
                                @click="showDialogNovoCliente"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>Adicionar novo cliente</span>
                    </v-tooltip>
                </div>
            </v-col>
            <v-col 
                v-if="isUsuarioInterno && tipoClienteSelected != null"
                cols="12"
                lg="3"
                md="3"
                sm="12"
            >
                <label>Tipo de Pedido</label>
                <v-combobox
                    v-model="tipoPedidoSelected"
                    :items="listTipoPedido"
                    :rules=[validations.required]
                    item-text="descricao"
                    item-value="id"
                    clearable
                    outlined
                    @change="impostoCalculado = false;"
                ></v-combobox>
            </v-col>
        </v-row>

        <v-row>
            <v-col 
                cols="12"
                lg="3"
                md="3"
                sm="12"
            >
                <label>Condição de Pagamento</label>
                <v-combobox
                    v-model="condicaoPagamentoSelected"
                    :items="listCondicaoPagamento"
                    :rules=[validations.required]
                    item-text="descricao"
                    item-value="id"
                    clearable
                    outlined
                    :loading="loadingCondicaoPagamento"
                    :disabled="abaterSaldoCampanhaCredito == 1 && isTipoDistribuidor == true"
                ></v-combobox>
            </v-col>
            <v-col 
                cols="12"
                lg="2"
                md="5"
                sm="12"
            >
                <label>Forma de Pagamento</label>
                <v-combobox
                    v-model="formaPagamentoSelected"
                    :items="listFormaPagamento"
                    :rules=[validations.required]
                    item-text="descricao"
                    item-value="id"
                    clearable
                    outlined
                    :disabled="abaterSaldoCampanhaCredito == 1 && isTipoDistribuidor == true"
                ></v-combobox>
            </v-col>
            <v-col 
                cols="12"
                lg="2"
                md="3"
                sm="12"
            >
                <label>Tipo de Frete</label>
                <v-combobox
                    v-model="tipoFreteSelected"
                    :items="listTipoFrete"
                    :rules=[validations.required]
                    item-text="descricao"
                    item-value="id"
                    clearable
                    outlined
                ></v-combobox>
            </v-col>
            
            <v-col 
                v-if="isUsuarioInterno && tipoClienteSelected != null && tipoClienteSelected != undefined && tipoClienteSelected.id != 5"
                cols="6"
                lg="2"
                md="3"
                sm="8"
            >
                <label>Aplicar Desconto</label>
                <v-text-field
                    v-model="descontoUsuarioFormatado" 
                    suffix=" %"
                    v-formatMoney="percFormat"
                    single-line
                    outlined
                >
                </v-text-field>
            </v-col>
            <v-col
                class="justify-center"
                v-if="isUsuarioInterno && tipoClienteSelected != null && tipoClienteSelected != undefined && tipoClienteSelected.id != 5"
                style="display: flex; align-content: center; align-items: center;"
                cols="2"
                lg="1"
                md="2"
                sm="2"
            >
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn 
                            class="btnActions"
                            v-on="on"
                            @click="confirmarDescontoUsuario()" 
                        >
                            Aplicar
                        </v-btn>
                    </template>
                    <span>Aplica o desconto a todos os produtos do carrinho</span>
                </v-tooltip>
            </v-col>
            <v-col
                class="justify-center"
                style="display: flex; align-content: center; align-items: center;"
                cols="2"
                lg="2"
                md="2"
                sm="2"
            >
                <v-switch 
                    v-model="entregaTurbo"
                    label="Entrega turbo"
                    color="var(--color__main)"
                    :value=1
                    @change="aplicarEntregaTurbo()"
                >
                </v-switch>
            </v-col>
        </v-row>
        
        <v-divider></v-divider>

        <v-row>
            <v-col 
                cols="12"
                lg="12"
                xl="9"
                md="12"
                sm="12"
            >
                <v-row
                    v-for="(itemProduto, index) in listCarrinho" :key="index"
                >
                    <v-col 
                        cols="12"
                    >
                        <v-expansion-panels
                            style="display: block;"
                            popout
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header
                                    :key="index"
                                >
                                    <v-row>
                                        <v-col 
                                            cols="2"
                                            lg="1"
                                            md="1"
                                            sm="1"
                                        >
                                            <v-list-item-avatar>
                                                <v-img :src="itemProduto.imagem"></v-img>
                                            </v-list-item-avatar>
                                        </v-col>

                                        <v-col 
                                            cols="10"
                                            :lg="isUsuarioInterno ? 4 : 5"
                                            md="12"
                                            sm="12"
                                        >
                                            <div style="display: flex; align-items: center;">
                                                <label>{{ itemProduto.codigoProduto }}</label>
                                                    
                                                <v-tooltip
                                                    v-if="itemProduto.certificacaoEX == 1" 
                                                    top
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-img 
                                                            v-if="itemProduto.certificacaoEX == 1" 
                                                            style="margin-left: 10px;" 
                                                            v-on="on" 
                                                            :src="require('@/assets/images/certificacaoex.png')" max-height="20" max-width="20" 
                                                        />
                                                    </template>
                                                    <span>Produto com Certificação EX</span>
                                                </v-tooltip>
                                            </div>

                                            <br />
                                            <label style="font-weight: normal !important;">{{ itemProduto.descricao }}</label> 
                                        </v-col>

                                        <v-col
                                            v-if="isUsuarioInterno && tipoClienteSelected != null && tipoClienteSelected != undefined && tipoClienteSelected.id != 5"
                                            style="display: flex; align-items: center;"
                                            cols="11"
                                            lg="2"
                                            md="3"
                                            sm="3"
                                        >
                                            <v-text-field
                                                style="height: 30px;"
                                                label="Desconto"
                                                v-model="itemProduto.desconto"
                                                suffix=" %"
                                                v-formatMoney="percFormat"
                                                outlined
                                                dense
                                                @blur="calcularTotal()"
                                                @keyup.prevent=""
                                                @click.stop=""
                                            >
                                            </v-text-field>
                                                
                                        </v-col>

                                        <v-col  
                                            style="display: flex; align-items: center;"
                                            cols="12"
                                            lg="3"
                                            md="4"
                                            sm="5"
                                        >
                                            <v-btn-toggle
                                            >
                                                <v-btn
                                                    class="btnCarrinho"
                                                    @click.stop=""
                                                    @click="atualizarQtde('-', itemProduto);"
                                                    outlined
                                                    x-small
                                                    fab
                                                    dense
                                                >
                                                    <v-icon>mdi-minus</v-icon>
                                                </v-btn>

                                                <v-text-field
                                                    name="campoQtde"
                                                    style="height: 30px;"
                                                    v-model="itemProduto.qtde"
                                                    single-line
                                                    outlined
                                                    dense
                                                    v-mask="'######'"
                                                    @blur="calcularTotal(); validarCampanhaCredito(true);"
                                                    @keyup.prevent=""
                                                    @click.stop=""
                                                >
                                                </v-text-field>
                                                
                                                <v-btn
                                                    class="btnCarrinho"
                                                    @click.stop=""
                                                    @click="atualizarQtde('+', itemProduto);"
                                                    outlined
                                                    x-small
                                                    fab
                                                    dense
                                                >
                                                    <v-icon>mdi-plus</v-icon>
                                                </v-btn>
                                            </v-btn-toggle>
                                        </v-col>

                                        <v-col 
                                            style="text-align: center; display: flex; align-items: center;"
                                            cols="12"
                                            lg="2"
                                            md="3"
                                            sm="4"
                                        >
                                            <div 
                                                :style="tipoClienteSelected != null ? 'display: flex; flex-direction: row; width: 100%; align-items: center;' : 'display: flex; flex-direction: row;'"
                                            >
                                                <div style="display: flex; flex-direction: column;">
                                                    <v-tooltip 
                                                        top
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <label 
                                                                v-on="on"
                                                                style="font-size: 20px; line-height: 30px;"
                                                            >
                                                                {{ mostrarBotaoImposto ? itemProduto.precoCustoTotal : itemProduto.precoUnitarioTotalComImpostos }}
                                                            </label>
                                                        </template>
                                                        <span v-if="mostrarBotaoImposto">Preço total (sem impostos)</span>
                                                        <span v-else>Preço total (com impostos)</span>
                                                    </v-tooltip>
                                                
                                                    <v-tooltip 
                                                        top
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <label 
                                                                v-on="on"
                                                                style="font-size: 10px;"
                                                            >
                                                                {{ itemProduto.valorUnitario }}
                                                            </label>
                                                        </template>
                                                        <span>Valor Unitário (Total / Qtde)</span>
                                                    </v-tooltip>

                                                </div>

                                                <div style="display: flex; flex-direction: column;">
                                                
                                                    <v-tooltip
                                                        v-if="mostrarEdicaoValorItem()"
                                                        top
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                v-if="mostrarEdicaoValorItem()"
                                                                v-on="on"
                                                                class="btnEdit"
                                                                @click.stop=""
                                                                outlined
                                                                small
                                                                fab
                                                                dense
                                                                @click="showDialogValorItem(itemProduto)"
                                                            >
                                                                <v-icon>mdi-calculator-variant-outline</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Editar Valor</span>
                                                    </v-tooltip>
                                                    
                                                    <v-tooltip
                                                        top
                                                    >
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                v-on="on"
                                                                class="btnEdit"
                                                                @click.stop=""
                                                                outlined
                                                                small
                                                                fab
                                                                dense
                                                                @click="clonarItem(itemProduto)"
                                                            >
                                                                <v-icon>mdi-content-duplicate</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Clonar Item</span>
                                                    </v-tooltip>

                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content style="font-size: 12px">

                                    <v-row 
                                        v-if="userLoggedGetters.perfil.visualizarMargemEfetiva == 1"
                                        style="border-top: solid 1px gray !important;"
                                    >
                                        <v-col
                                            cols="6"
                                            lg="4"
                                            md="4"
                                            sm="6"
                                        >
                                            <label>Margem Efetiva</label>
                                        </v-col>
                                        <v-col 
                                            cols="6"
                                            lg="4"
                                            md="6"
                                            sm="6"
                                        >
                                            <label>{{ itemProduto.margemEfetiva }}</label>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="itemProduto.certificacaoEX == 1">
                                        <v-col
                                            cols="6"
                                            lg="4"
                                            md="4"
                                            sm="6"
                                        >
                                            <label>Valor Certificação</label>
                                        </v-col>
                                        <v-col 
                                            cols="6"
                                            lg="4"
                                            md="6"
                                            sm="6"
                                        >
                                            <label>{{ itemProduto.valorCertificacaoEXFormatted }}</label>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col
                                            cols="6"
                                            lg="4"
                                            md="4"
                                            sm="6"
                                        >
                                            <label>Preço (sem impostos)</label>
                                        </v-col>
                                        <v-col 
                                            cols="6"
                                            lg="4"
                                            md="6"
                                            sm="6"
                                        >
                                            <label>{{ itemProduto.precoAplicadoManualmente == 1 ? itemProduto.precoSemImpostosOriginal : itemProduto.precoSemImpostos }}</label>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="impostoCalculado">
                                        <v-col
                                            cols="6"
                                            lg="4"
                                            md="4"
                                            sm="6"
                                        >
                                            <label>Preço (com impostos)</label>
                                        </v-col>
                                        <v-col 
                                            cols="6"
                                            lg="4"
                                            md="6"
                                            sm="6"
                                        >
                                            <label>{{ itemProduto.precoUnitarioComImpostos }}</label>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col
                                            cols="6"
                                            lg="4"
                                            md="4"
                                            sm="6"
                                        >
                                            <label>NCM</label>
                                        </v-col>
                                        <v-col 
                                            cols="6"
                                            lg="4"
                                            md="6"
                                            sm="6"
                                        >
                                            <label>{{ itemProduto.codigoNCM }}</label>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col
                                            cols="6"
                                            lg="4"
                                            md="4"
                                            sm="6"
                                        >
                                            <label>Origem</label>
                                        </v-col>
                                        <v-col 
                                            cols="6"
                                            lg="4"
                                            md="6"
                                            sm="6"
                                        >
                                            <label>{{ itemProduto.origem }}</label>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="isTipoKeyAccount == true">
                                        <v-col
                                            cols="6"
                                            lg="4"
                                            md="4"
                                            sm="6"
                                        >
                                            <label>Key Account</label>
                                        </v-col>
                                        <v-col 
                                            cols="6"
                                            lg="4"
                                            md="6"
                                            sm="6"
                                        >
                                            <label>{{ itemProduto.keyAccount == 1 ? 'SIM' : 'NÃO' }}</label>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="impostoCalculado">
                                        <v-col
                                            cols="6"
                                            lg="4"
                                            md="4"
                                            sm="6"
                                        >
                                            <label>ICMS</label>
                                        </v-col>
                                        <v-col 
                                            cols="6"
                                            lg="4"
                                            md="6"
                                            sm="6"
                                        >
                                            <label>{{ itemProduto.aliqICMSFormatted }}% - {{ itemProduto.valICMSFormatted }}</label>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="impostoCalculado">
                                        <v-col
                                            cols="6"
                                            lg="4"
                                            md="4"
                                            sm="6"
                                        >
                                            <label>PIS</label>
                                        </v-col>
                                        <v-col 
                                            cols="6"
                                            lg="4"
                                            md="6"
                                            sm="6"
                                        >
                                            <label>{{ itemProduto.aliqPISFormatted }}% - {{ itemProduto.valPISFormatted }}</label>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="impostoCalculado">
                                        <v-col
                                            cols="6"
                                            lg="4"
                                            md="4"
                                            sm="6"
                                        >
                                            <label>COFINS</label>
                                        </v-col>
                                        <v-col 
                                            cols="6"
                                            lg="4"
                                            md="6"
                                            sm="6"
                                        >
                                            <label>{{ itemProduto.aliqCOFINSFormatted }}% - {{ itemProduto.valCOFINSFormatted }}</label>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="impostoCalculado">
                                        <v-col
                                            cols="6"
                                            lg="4"
                                            md="4"
                                            sm="6"
                                        >
                                            <label>IPI</label>
                                        </v-col>
                                        <v-col 
                                            cols="6"
                                            lg="4"
                                            md="6"
                                            sm="6"
                                        >
                                            <label>{{ itemProduto.aliqIPIFormatted }}% - {{ itemProduto.valorIPIFormatted }} (a incluir)</label>
                                        </v-col>
                                    </v-row>
                        
                                    <v-row
                                        v-if="itemProduto.descontoPorQtdeAplicado && isUsuarioInterno"
                                    >
                                        <v-col
                                            cols="6"
                                            lg="4"
                                            md="4"
                                            sm="6"
                                        >
                                            <label><strong>Desconto</strong></label>
                                        </v-col>
                                        <v-col 
                                            cols="6"
                                            lg="4"
                                            md="6"
                                            sm="6"
                                        >
                                            <label>
                                                {{ itemProduto.descontoPorQtdePercentual }}
                                            </label>
                                        </v-col>
                                    </v-row>
                        
                                    <v-row
                                        v-if="mostrarDescontoDistribuidor"
                                    >
                                        <v-col
                                            cols="6"
                                            lg="4"
                                            md="4"
                                            sm="6"
                                        >
                                            <label><strong>Desconto Distribuidor</strong></label>
                                        </v-col>
                                        <v-col 
                                            cols="6"
                                            lg="4"
                                            md="6"
                                            sm="6"
                                        >
                                            <label 
                                                v-if="distribuidorConfig != null && distribuidorConfig != undefined && distribuidorConfig.listDesconto != null && distribuidorConfig.listDesconto != undefined && distribuidorConfig.listDesconto.length > 0"
                                            >
                                                {{ getDescontoDistribuidor(itemProduto) }} %
                                            </label>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="impostoCalculado">
                                        <v-col
                                            cols="6"
                                            lg="4"
                                            md="4"
                                            sm="6"
                                        >
                                            <label>Qtde em Estoque</label>
                                        </v-col>
                                        <v-col 
                                            cols="6"
                                            lg="4"
                                            md="6"
                                            sm="6"
                                        >
                                            <label>{{ itemProduto.qtdeEstoque }}</label>
                                        </v-col>
                                    </v-row>

                                    <v-row v-if="impostoCalculado">
                                        <v-col
                                            cols="6"
                                            lg="4"
                                            md="4"
                                            sm="6"
                                        >
                                            <label>Prazo de Entrega</label>
                                        </v-col>
                                        <v-col 
                                            cols="6"
                                            lg="4"
                                            md="6"
                                            sm="6"
                                        >
                                            <label>{{ itemProduto.prazoEntrega }} {{ itemProduto.prazoEntregaDescricao }}</label>
                                            <v-tooltip 
                                                top
                                                v-if="mostrarEdicaoPrazoEntrega(itemProduto)"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        v-if="mostrarEdicaoPrazoEntrega(itemProduto)"
                                                        v-on="on"
                                                        class="btnEdit"
                                                        @click.stop=""
                                                        outlined
                                                        small
                                                        fab
                                                        dense
                                                        @click="showDialogPrazoEntrega(itemProduto)"
                                                    >
                                                        <v-icon>mdi-pencil</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Editar prazo de entrega</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                        
                                    <v-row
                                        v-if="mostrarCampanhas"
                                    >
                                        <v-col
                                            cols="6"
                                            lg="4"
                                            md="4"
                                            sm="6"
                                        >
                                            <label><strong>Descontos aplicados por Campanhas</strong></label>
                                        </v-col>
                                        <v-col 
                                            cols="6"
                                            lg="4"
                                            md="6"
                                            sm="6"
                                        >
                                            <label 
                                                v-if="listCampanhaEstoque != null && listCampanhaEstoque != undefined && listCampanhaEstoque.length > 0"
                                            >
                                                Campanha de estoque - {{ itemProduto.descontoCampanhaEstoque }} %
                                            </label>

                                            <br v-if="listCampanhaEstoque != null && listCampanhaEstoque != undefined && listCampanhaEstoque.length > 0" />

                                            <label 
                                                v-if="mostrarCampanhaCredito"
                                            >
                                                Campanha de crédito - {{ mostrarCampanhaCredito ? listCampanhaCredito[0].descontoFormatted + ' %' : '' }}
                                            </label>

                                            <br v-if="mostrarCampanhaCredito" />
                                            
                                            <label 
                                                v-if="itemProduto.descontoCampanhaOuroPrataDescricao != null && itemProduto.descontoCampanhaOuroPrataDescricao != undefined && 
                                                      itemProduto.descontoCampanhaOuroPrata != null && itemProduto.descontoCampanhaOuroPrata != undefined && itemProduto.descontoCampanhaOuroPrata > 0"
                                            >
                                                {{ itemProduto.descontoCampanhaOuroPrataDescricao }} - {{ itemProduto.descontoCampanhaOuroPrata }} %
                                            </label>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col
                                            cols="6"
                                            lg="4"
                                            md="4"
                                            sm="6"
                                        >
                                            <v-switch 
                                                v-model="itemProduto.entregaProgramada"
                                                label="Entrega Programada?"
                                                :value=1
                                                color="var(--color__main)"
                                            >
                                            </v-switch>
                                        </v-col>
                                        <v-col 
                                            cols="6"
                                            lg="4"
                                            md="6"
                                            sm="6"
                                        >
                                            <v-menu
                                                v-if="itemProduto.entregaProgramada == 1"
                                                ref="mnuDataEntrega"
                                                v-model="itemProduto.mnuDataEntrega"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="300px"
                                                min-width="auto"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="itemProduto.dataEntregaFormatted"
                                                        prepend-inner-icon="mdi-calendar"
                                                        v-mask="'##/##/####'"
                                                        v-bind="attrs"
                                                        outlined
                                                        single-line
                                                        v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-model="itemProduto.dataEntrega"
                                                    no-title
                                                    @input="formatarData(itemProduto)"
                                                    :min="dataAtual"
                                                    locale="pt-br"
                                                ></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col
                                            cols="6"
                                            lg="4"
                                            md="4"
                                            sm="6"
                                        >
                                            <v-switch 
                                                v-model="itemProduto.produtoAgrupado"
                                                label="Produto Agrupado?"
                                                :value=1
                                                color="var(--color__main)"
                                            >
                                            </v-switch>
                                        </v-col>
                                        <v-col 
                                            cols="6"
                                            lg="4"
                                            md="6"
                                            sm="6"
                                        >
                                            <v-text-field
                                                v-if="itemProduto.produtoAgrupado == 1"
                                                v-model="itemProduto.tagAgrupamento" 
                                                single-line
                                                outlined
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-row
                                        v-if="isUsuarioInterno"
                                    >
                                        <v-col
                                            cols="6"
                                            lg="4"
                                            md="4"
                                            sm="6"
                                        >
                                            <label><strong>Observações Internas</strong></label>
                                        </v-col>
                                        <v-col 
                                            cols="6"
                                            lg="12"
                                            md="6"
                                            sm="6"
                                        >
                                            <v-textarea
                                                v-model="itemProduto.observacao"
                                                single-line
                                                rows="3"
                                                row-height="30"
                                                outlined
                                            >
                                            </v-textarea>
                                        </v-col>
                                    </v-row>

                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>
            </v-col>

            <v-col 
                style="margin-top: 15px; text-align: center;"
                cols="12"
                lg="12"
                xl="3"
                md="12"
                sm="12"
            >
                <v-card
                    class="mx-auto"
                    max-width="344"
                >
                    <v-card-title class="text-h6 lighten-2 justify-center" style="border-bottom: solid 1px var(--color__cinza)">
                        Resumo da Cotação
                    </v-card-title>
                    <v-card-text style="margin-top: 20px;">

                        <v-row
                            v-if="showAnaliseCredito && impostoCalculado == true"
                        >
                            <v-col
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                            >   
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn 
                                            class="botaoSolicitar botaoTamanhoFull"
                                            outlined
                                            v-on="on"
                                            @click="abrirDialogAnaliseCredito()"
                                        >
                                            Análise de Crédito
                                        </v-btn>
                                    </template>
                                    <span>Solicitar previa da análise de crédito</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                        
                        <v-row>
                            <v-col
                                v-if="mostrarFlagCampanhaCredito"
                                style="margin-left: 10%;"
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                            >   
                                <v-switch 
                                    v-model="abaterSaldoCampanhaCredito"
                                    :disabled="disableSaldoCampanhaCredito"
                                    label="Abater saldo da campanha de crédito?"
                                    color="var(--color__main)"
                                    :value=0
                                    @change="validarCampanhaCredito(true);"
                                >
                                </v-switch>
                            </v-col>
                            <v-col
                                v-if="isTipoDistribuidor"
                                cols="12"
                                lg="12"
                                md="12"
                                sm="12"
                            >   
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn 
                                            class="botaoTamanhoFull"
                                            outlined
                                            v-on="on"
                                            @click="abrirDialogSaldoCampanha()"
                                        >
                                            Visualizar Saldo de Campanha
                                        </v-btn>
                                    </template>
                                    <span>Visualizar saldo de campanha dos pedidos</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                        
                        <v-row>
                            <v-col
                                cols="12"
                                lg="7"
                                md="6"
                                sm="12"
                            >
                                <label style="color: var(--color__main) !important; font-size: 15px;">Total Cotação</label>
                            </v-col>
                            <v-col
                                cols="12"
                                lg="5"
                                md="6"
                                sm="12"
                                style="text-align: right;"
                            >
                                <label style="color: var(--color__main) !important; font-size: 17px;">{{ valorTotalFormatado }}</label>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions
                        class="justify-center"
                    >
                        <v-btn
                            v-if="mostrarBotaoGravar"
                            class="mx-2"
                            @click="validarCotacao()"
                        >
                            Gravar Cotação
                        </v-btn>
                        <v-btn
                            v-if="mostrarBotaoImposto"
                            class="mx-2"
                            color="#aaa !important"
                            @click="confirmarCalculoImpostos()"
                        >
                            <v-tooltip 
                                top
                            >
                                <template v-slot:activator="{ on }">
                                    <label v-on="on" style="color: white;">
                                        Verificar Imp. / Disp.
                                    </label>
                                </template>
                                <span>Verificar Impostos e Disponibilidade</span>
                            </v-tooltip>                            
                        </v-btn>
                    </v-card-actions>

                </v-card>
            </v-col>
        </v-row>

        <ActionDialog 
            v-on:methodAcao1ToCall="dialog.methodAcao1"
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :button1Text="dialog.button1Text"
            :button2Text="dialog.button2Text"
            :dismissDialog="dialog.dismissDialog"
            :params="dialog.params"
        />

        <DialogPrazoEntregaEdit 
            v-on:methodConfirmToCall="dialogPrazoEntrega.methodConfirm"
            :showDialogPrazoEntregaEdit.sync="dialogPrazoEntrega.show"
            :prazoEntrega="dialogPrazoEntrega.prazoEntrega"
            :prazoEntregaOriginal="dialogPrazoEntrega.prazoEntregaOriginal"
        />

        <DialogValorItemEdit 
            v-on:methodConfirmToCall="dialogValorItem.methodConfirm"
            :showDialogValorItemEdit.sync="dialogValorItem.show"
            :id="dialogValorItem.id"
            :codigoProduto="dialogValorItem.codigoProduto"
            :precoLista="dialogValorItem.precoLista"
            :valor="dialogValorItem.valor"
        />

        <DialogPedidosCampanha 
            :showDialogPedidosCampanha.sync="dialogSaldoCampanha.show"
            :idAux="dialogSaldoCampanha.idAux"
            :idDistribuidor="dialogSaldoCampanha.idDistribuidor"
        />

        <DialogAnaliseCredito 
            :showDialogAnaliseCredito.sync="dialogAnaliseCredito.show"
            :dadosAnaliseCredito="dialogAnaliseCredito.dadosAnaliseCredito"
        />
        
        <DialogMessage 
            v-on:methodOKToCall="dialogMessage.methodOK"
            :showDialogMessage.sync="dialogMessage.show"
            :headerTitle="dialogMessage.headerText"
            :headerColor="dialogMessage.headerColor"
            :headerFontColor="dialogMessage.headerFontColor"
            :bodyText="dialogMessage.bodyText"
        />

        <DialogCliente
            v-on:methodConfirmToCall="dialogCliente.methodConfirm"
            :showDialogCliente.sync="dialogCliente.show"
            :idAux="dialogCliente.idAux"
        />

    </v-container>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import { 
        formaterDecimalBRServer, 
        formaterDecimalBR, 
        formatDateToServer,
        formatarDataPadraoPtBR
    } from '@/utilities/Utils';
    import ActionDialog from "@/components/Layout/ActionDialog";
    import DialogPrazoEntregaEdit from "@/components/Layout/DialogPrazoEntregaEdit";
    import DialogValorItemEdit from "@/components/Layout/DialogValorItemEdit";
    import DialogPedidosCampanha from "@/components/Layout/DialogPedidosCampanha";
    import DialogAnaliseCredito from "@/components/Layout/DialogAnaliseCredito";
    import DialogMessage from "@/components/Layout/DialogMessage";
    import DialogCliente from "@/components/Layout/DialogCliente";
    import moment from 'moment';
    import { 
        CONSULTAR_FABRICA_DESCRICAO, 
        LIST_TIPO_PEDIDO, 
        LIST_TIPO_CLIENTE 
    } from "@/utilities/Enums";    

    export default ({

        mixins: [Helpers],

        components: {
            ActionDialog,
            DialogPrazoEntregaEdit,
            DialogValorItemEdit,
            DialogPedidosCampanha,
            DialogAnaliseCredito,
            DialogMessage,
            DialogCliente
        },

        data: vm => ({

            listCarrinho: [],
            descontoUsuarioFormatado: "0,00",
            valorTotalFormatado: "R$ 0,00",
            valorTotalSemDescontoCampanha: "R$ 0,00",
            valorTotalIPI: 0,
            itemProdutoExcluir: null,
            distribuidorSelected: null,
            tipoClienteSelected: null,

            listTipoCliente: LIST_TIPO_CLIENTE,

            listDescontos: [],
            listDistribuidor: [],
            listClientes: [],
            listClientesFiltered: [],
            listCondicaoPagamento: [],
            condicaoPagamentoSelected: null,
            listFormaPagamento: [],
            formaPagamentoSelected: null,
            listTipoFrete: [],
            tipoFreteSelected: null,
            loadingDistribuidor: false,
            loadingClientes: false,
            loadingCondicaoPagamento: false,

            listCampanhaEstoque: [],
            listCampanhaCredito: [],
            abaterSaldoCampanhaCredito: true,
            disableSaldoCampanhaCredito: false,
            elegivelParaCampanha: true,
            distribuidorConfig: null,
            listCampanhaOuroPrata: [],
            listCampanhaOuroPrataConsultada: [],

            listProdutoKeyAccount: [],

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                button1Text: "Cancelar",
                button2Text: "Confirmar",
                methodAcao1: ( () => {} ),
                methodConfirm: ( () => {} ),
                dismissDialog: true,
                params: null
            },

            textoDias: "dias úteis",

            dialogPrazoEntrega: {
                show: false,
                prazoEntrega: "0",
                prazoEntregaOriginal: "0",
                methodConfirm: ( () => {} )
            },

            dialogValorItem: {
                show: false,
                id: 0,
                codigoProduto: "",
                precoLista: "R$ 0,00",
                valor: "R$ 0,00",
                methodConfirm: ( () => {} )
            },

            dialogSaldoCampanha: {
                show: false,
                idAux: 0,
                idDistribuidor: "0"
            },

            dadosAnaliseCredito: {
                nomeCliente: "",
                limiteCreditoFormatted: "R$ 0,00",
                descricao: "",
                descricaoAnaliseDias: ""
            },

            dialogAnaliseCredito: {
                show: false,
                dadosAnaliseCredito: vm.dadosAnaliseCredito
            },

            dialogCliente: {
                show: false,
                idAux: 0,
                methodConfirm: ( () => {} )
            },

            og: Math.pow(10, 2),

            percFormat: {
                suffix: '',
                thousands: '',
                decimal: ',',
                precision: 2,
            },

            consultarFabricaDescricao: CONSULTAR_FABRICA_DESCRICAO,
            impostoCalculado: false,

            tipoPedidoSelected: null,
            listTipoPedido: LIST_TIPO_PEDIDO,

            listCompraMinimaValidacao: [],

            dataAtual: moment().clone().startOf('day').format('YYYY-MM-DD'),

            showAnaliseCredito: false,
            analiseCredito: null,

            configuracaoSistema: null,

            entregaTurbo: 0,

            dialogMessage: {
                show: false,
                headerText: "",
                headerColor: "",
                headerFontColor: "",
                bodyText: "",
                methodOK: ( () => {} ),
            },
            
            validations: {
                required: required,
            },
        }),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState' }),
            ...mapGetters('carrinhoModule', { carrinhoStateGetters: 'carrinhoState' }),

            mostrarDescontoDistribuidor() {

                let mostrarDescontoDistribuidor = false;

                if (this.distribuidorConfig != null && 
                    this.distribuidorConfig != undefined && 
                    this.distribuidorConfig.listDesconto != null && 
                    this.distribuidorConfig.listDesconto != undefined && 
                    this.distribuidorConfig.listDesconto.length > 0) {
                    mostrarDescontoDistribuidor = true
                }

                return mostrarDescontoDistribuidor;
            },

            mostrarCampanhaCredito() {

                return this.listCampanhaCredito != null && 
                       this.listCampanhaCredito != undefined && 
                       this.listCampanhaCredito.length > 0 && 
                       this.distribuidorConfig != null && 
                       this.distribuidorConfig != undefined;
            },

            mostrarBotaoConsultaSaldoCampanha() {

                if (this.isUsuarioInterno == true) {
                    return this.tipoClienteSelected != null && 
                        this.tipoClienteSelected.id == 2 && 
                        this.distribuidorConfig != null && 
                        this.distribuidorConfig != undefined;

                }
                else {
                    return true;
                }
            },

            mostrarCampanhas() {

                let mostrarCampanha = false;

                if (this.listCampanhaEstoque != null && this.listCampanhaEstoque != undefined && this.listCampanhaEstoque.length > 0) {
                    mostrarCampanha = true
                }
                else if (this.mostrarCampanhaCredito) {
                    mostrarCampanha = true
                }
                else if (this.listCampanhaOuroPrata != null && this.listCampanhaOuroPrata != undefined && this.listCampanhaOuroPrata.length > 0) {
                    mostrarCampanha = true
                }

                return mostrarCampanha;
            },

            mostrarBotaoGravar() {

                return this.impostoCalculado == true;
            },

            mostrarBotaoImposto() {

                return this.impostoCalculado == false;
            },

            isTipoKeyAccount() {
                return this.tipoClienteSelected != null && 
                       this.tipoClienteSelected != undefined && 
                       this.tipoClienteSelected.id == 5
            },

            mostrarBotaoNovoCliente() {
                const idTipoCliente = this.tipoClienteSelected != null && 
                                      this.tipoClienteSelected != undefined && 
                                      this.tipoClienteSelected.id;

                return this.isUsuarioInterno == true && 
                       (idTipoCliente == 1 || idTipoCliente == 3 || idTipoCliente == 4); //TIPO CLIENTE: OEM ou REVENDA ou USUÁRIO FINAL
            },

            isTipoDistribuidor() {
                return (this.isUsuarioInterno == false ||
                        (this.tipoClienteSelected != null && 
                         this.tipoClienteSelected != undefined && 
                         this.tipoClienteSelected.id == 2)
                       )
                       
                        
            },

            temDistribuidorPai() {
                return this.userLoggedGetters.temDistribuidorPai == 1;
            },

            isUsuarioInterno() {
                return this.userLoggedGetters.usuarioInterno == 1;
            },

            mostrarFlagCampanhaCredito() {
                return this.userLoggedGetters.perfil.visualizarFlagAbatimentoCampanhaCredito == 1;
            },
        },

        watch: {
            "carrinhoStateGetters.reload"() {
                this.carregarCarrinho();
            },

            "loadingClientes"() {
                this.verificaKeyAccount();
            }
        },

        methods: {
            mostrarEdicaoPrazoEntrega(itemProduto) {
                return this.isUsuarioInterno == true && itemProduto.prazoEntrega != this.consultarFabricaDescricao;
            },

            mostrarEdicaoValorItem() {

                let idTipoCliente = 0;

                if (this.tipoClienteSelected != null && this.tipoClienteSelected != undefined) {
                    idTipoCliente = this.tipoClienteSelected.id;
                }

                return this.isUsuarioInterno == true && this.impostoCalculado == true && (idTipoCliente == 1 || idTipoCliente == 2 || idTipoCliente == 3 || idTipoCliente == 4);
            },

            async getLists() {
                
                this.listFormaPagamento = await this.$store.dispatch("formaPagamentoModule/List");
                this.listTipoFrete = await this.$store.dispatch("tipoFreteModule/List");

                this.loadingDistribuidor = true;
                this.listDistribuidor = await this.$store.dispatch("empresaModule/ListDistribuidor");
                this.loadingDistribuidor = false;

                this.loadClientes();
            },

            async loadClientes() {
                this.loadingClientes = true;
                this.listClientes = await this.$store.dispatch("empresaModule/ListClientes");
                this.loadingClientes = false;
            },

            async verificaKeyAccount() {

                if (this.tipoClienteSelected != null && this.tipoClienteSelected != undefined && this.tipoClienteSelected.id == 5) {

                    let listKeyAccount = await this.$store.dispatch("empresaModule/ListKeyAccount");

                    this.listClientesFiltered = [...listKeyAccount];
                }
                else {
                    this.listClientesFiltered = [...this.listClientes];
                }
            },

            async getListProdutosKeyAccount() {
                    
                let idTipoCliente = 0;

                if (this.tipoClienteSelected != null && this.tipoClienteSelected != undefined) {
                    idTipoCliente = this.tipoClienteSelected.id;
                }

                if (idTipoCliente == 5) {

                    if (this.clienteSelected != null && this.clienteSelected != undefined) {
                        this.listProdutoKeyAccount = await this.$store.dispatch("keyAccountModule/ListarProdutos", this.clienteSelected.id);

                        await this.carregarDescontos(false);

                        this.calcularTotal();
                    }
                }
            },
            
            async carregarCarrinho() {
                let response = await this.$store.dispatch("carrinhoModule/List");
                
                if (response != null && response != undefined) {
                    this.listCarrinho = response;
                    this.calcularTotal();
                }
            },

            async atualizarQtde(operacao, itemProduto) {
                if (operacao == '+') {
                    itemProduto.qtde = parseInt(itemProduto.qtde) + 1;
                }
                else {
                    itemProduto.qtde = parseInt(itemProduto.qtde) - 1;
                }

                if (itemProduto.qtde == null || itemProduto.qtde == undefined || itemProduto.qtde == 0) {
                    itemProduto.qtde = 0
                }
                
                await this.$store.dispatch("carrinhoModule/CreateUpdate", { itemProduto, forcarAdicionarProduto: false });

                if (itemProduto.qtde == 0) {
                    this.confirmarExclusaoItem(itemProduto)
                }
                
                await this.calcularTotal(true);

                this.validarCampanhaCredito(true);
            },

            confirmarExclusaoItem(itemProduto) {

                this.itemProdutoExcluir = itemProduto;

                this.dialog = {
                    show: true,
                    headerText: 'Confirmação',
                    bodyText: 'Excluir item do carrinho?',
                    methodAcao1: ( () => {} ),
                    methodConfirm: this.excluirItem,
                    params: 0
                };

            },

            async excluirItem() {

                this.showLoading();

                await this.$store.dispatch("carrinhoModule/ExcluirItem", this.itemProdutoExcluir);
                this.carrinhoStateGetters.reload = !this.carrinhoStateGetters.reload;

                this.carregarCarrinho();

                this.hideLoading();
            },

            confirmarLimpezaCarrinho() {

                this.dialog = {
                    show: true,
                    headerText: 'Confirmação',
                    bodyText: 'Você irá esvaziar toda cesta, confirma sua decisão?',
                    methodAcao1: ( () => {} ),
                    methodConfirm: this.limparCarrinho,
                    params: 0
                };

            },

            async limparCarrinho() {

                this.showLoading();

                await this.$store.dispatch("carrinhoModule/LimparCarrinho");
                this.carrinhoStateGetters.reload = !this.carrinhoStateGetters.reload;

                this.tipoClienteSelected = null;
                this.distribuidorSelected = null;
                this.condicaoPagamentoSelected = null;
                this.formaPagamentoSelected = null;
                this.tipoFreteSelected = null;
                this.listCarrinho = [];
                this.valorTotalFormatado = "R$ 0,00";
                this.showAnaliseCredito = false;

                this.carregarCarrinho();

                this.hideLoading();
            },

            async calcularImpostos(item, custoTotal) {

                if (item.precoAplicadoManualmente != 1) {
                    let tipoCliente = 'R';
                    let codigo = '';
                    let loja = '';
                    
                    if (this.tipoPedidoSelected != null && this.tipoPedidoSelected != undefined) {
                        tipoCliente = this.tipoPedidoSelected.id;
                    }

                    if (this.isUsuarioInterno == true) {

                        if (this.tipoClienteSelected != null && this.tipoClienteSelected != undefined) {
                            
                            if (this.tipoClienteSelected.id == 2) {
                                if (this.distribuidorSelected != null && this.distribuidorSelected != undefined) {
                                    codigo = this.distribuidorSelected.id;
                                    loja = this.distribuidorSelected.branch;
                                }
                            }
                            else {
                                if (this.clienteSelected != null && this.clienteSelected != undefined) {
                                    codigo = this.clienteSelected.id;
                                    loja = this.clienteSelected.branch;
                                }
                            }
                        }
                    }
                    else {
                        codigo = this.userLoggedGetters.distribuidorSelected.id;
                        loja = this.userLoggedGetters.distribuidorSelected.branch;
                    }

                    const impostoRequest = {
                        CODIGO: codigo,
                        LOJA: loja,
                        TIPOCLI: tipoCliente,
                        LISTITENS: [{
                            PROD: item.codigoProduto,
                            VALOR: custoTotal,
                            NCM: item.codigoNCM,
                            ORIGEM: item.importado.toString()
                        }]
                    };

                    const resultImpostos = await this.$store.dispatch("produtoModule/ConsultarImpostos", impostoRequest);

                    if (resultImpostos && resultImpostos.totalValorMercadoria != 0 && resultImpostos.listItens.length > 0) {
                        custoTotal += resultImpostos.listItens[0].valICM + resultImpostos.listItens[0].valPIS + resultImpostos.listItens[0].valCOF;
                        item.aliqICMSFormatted = formaterDecimalBR(resultImpostos.listItens[0].aliqICM);
                        item.valICMSFormatted = formaterDecimalBR(resultImpostos.listItens[0].valICM);
                        item.aliqPISFormatted = formaterDecimalBR(resultImpostos.listItens[0].aliqPIS);
                        item.valPISFormatted = formaterDecimalBR(resultImpostos.listItens[0].valPIS);
                        item.aliqCOFINSFormatted = formaterDecimalBR(resultImpostos.listItens[0].aliqCOF);
                        item.valCOFINSFormatted = formaterDecimalBR(resultImpostos.listItens[0].valCOF);
                        item.aliqIPIFormatted = formaterDecimalBR(resultImpostos.listItens[0].aliqIPI);
                        item.valorIPIFormatted = formaterDecimalBR(resultImpostos.listItens[0].valIPI);
                        item.valorIPI = resultImpostos.listItens[0].valIPI;
                        item.qtdeEstoque = resultImpostos.listItens[0].qtdDisp;
                    }
                    else {
                        item.aliqICMSFormatted = formaterDecimalBR(0);
                        item.valICMSFormatted = formaterDecimalBR(0);
                        item.aliqPISFormatted = formaterDecimalBR(0);
                        item.valPISFormatted = formaterDecimalBR(0);
                        item.aliqCOFINSFormatted = formaterDecimalBR(0);
                        item.valCOFINSFormatted = formaterDecimalBR(0);
                        item.valorIPIFormatted = formaterDecimalBR(0);
                        item.valorIPI = 0;
                        item.qtdeEstoque = 0;
                    }

                    item.showDialogFaturamentoParcial = false;
                    item.prazoEntregaDescricao = this.textoDias;

                    let prazoEntregaConfigurado = [];

                    if (item.listPrazoEntrega != null &&
                        item.listPrazoEntrega != undefined) {
                        prazoEntregaConfigurado = item.listPrazoEntrega;
                    }

                    if (prazoEntregaConfigurado != null && 
                        prazoEntregaConfigurado != undefined &&
                        prazoEntregaConfigurado.length > 0) {

                        if (item.qtdeEstoque <= 0) {
                            const listPrazos = prazoEntregaConfigurado.filter(prz => item.qtde >= prz.qtde);
                            const qtdePrazoEntrega = listPrazos[listPrazos.length - 1].prazoEntrega;

                            if (qtdePrazoEntrega == 0) {
                                item.prazoEntrega = this.consultarFabricaDescricao;
                                item.prazoEntregaDescricao = '';
                            }
                            else {
                                item.prazoEntrega = `${qtdePrazoEntrega}`;
                            }
                        }
                        else if (item.qtdeEstoque >= item.qtde) {
                            item.prazoEntrega = `3`
                        }
                        else {
                            const listPrazos = prazoEntregaConfigurado.filter(prz => item.qtde >= prz.qtde && prz.prazoEntregaDefinido == 1);
                            const qtdePrazoEntrega = listPrazos[listPrazos.length - 1].prazoEntrega;

                            item.prazoEntrega = `3`
                            item.prazoEntregaDescricao = `${this.textoDias} (${item.qtdeEstoque} peças) e ${qtdePrazoEntrega} dias úteis para as restantes`;
                            item.showDialogFaturamentoParcial = true;
                        }
                    }
                    else {
                        item.prazoEntrega = this.consultarFabricaDescricao;
                        item.prazoEntregaDescricao = '';
                    }
                    item.prazoEntregaOriginal = item.prazoEntrega;

                    item.faturamentoParcial = 0;
                }

                return custoTotal;
            },

            async calcularTotal(showLoading = true, calculaQtde = true) {

                if (showLoading == true) {
                    this.showLoading();
                }
                
                var valorTotal = 0;
                this.valorTotalIPI = 0;
                this.impostoCalculado = true;
                const percentualEntregaTurbo = 0.5;
                this.valorTotalFormatado = "R$ 0,00";
                
                await this.listCarrinho.forEach(async item => {

                    try {

                        var novoPreco = 0;

                        if (item.qtde == null || item.qtde == undefined || item.qtde == 0) {
                            item.qtde = 1
                        }

                        let valorEntregaTurbo = 0;

                        if (item.precoAplicadoManualmente == 1) {
                            novoPreco = parseFloat(formaterDecimalBRServer(item.novoPreco));

                            if (this.entregaTurbo == 1) {
                                //pedidos com entrega turbo, aumenta o preço em 50%
                                valorEntregaTurbo = parseFloat(formaterDecimalBRServer(item.novoPreco)) * percentualEntregaTurbo;
                                novoPreco += valorEntregaTurbo;
                            }

                        }
                        else {
                            this.impostoCalculado = false;
                        }

                        let descontoUsuario = formaterDecimalBRServer(item.desconto);
                        let moedaProduto = "R$";

                        let descontoPermitido = await this.descontoUsuarioPermitido(descontoUsuario, false);

                        if (descontoPermitido) {                        

                            let precoCusto = parseFloat(formaterDecimalBRServer(item.precoCusto));

                            if (this.entregaTurbo == 1) {
                                //pedidos com entrega turbo, aumenta o preço em 50%
                                valorEntregaTurbo = parseFloat(formaterDecimalBRServer(item.precoCusto)) * percentualEntregaTurbo;
                                precoCusto += valorEntregaTurbo;
                            }

                            let produtoKeyAccountAplicado = false;

                            if (this.listProdutoKeyAccount != null && this.listProdutoKeyAccount != undefined && this.listProdutoKeyAccount.length > 0) {

                                let produtoKeyAccount = this.listProdutoKeyAccount.filter(key => key.codigoProduto == item.codigoProduto);

                                if (produtoKeyAccount != null && produtoKeyAccount != undefined && produtoKeyAccount.length > 0) {
                                    precoCusto = produtoKeyAccount[0].preco;
                                    moedaProduto = produtoKeyAccount[0].cambioSimbolo;
                                    produtoKeyAccountAplicado = true;
                                    item.keyAccount = 1;
                                }
                            }

                            const listDesconto = this.listDescontos.filter(des => des.idProduto == item.idProduto && des.qtdePecasLimite <= item.qtde && produtoKeyAccountAplicado == false);

                            item.descontoPorQtdeAplicado = false;

                            let desconto = 1;
                            let descontoBase = 1;
                            if (listDesconto != null && listDesconto != undefined && listDesconto.length > 0) {

                                item.descontoPorQtdeAplicado = true;
                                let descricaoDesconto = "";
                                let descricaoDescontoBase = "";
                                let descontoAplicado = false;
                                let descontoBaseAplicado = false;

                                listDesconto.forEach(itemDesconto => {

                                    if (itemDesconto.desconto > 0 && descontoAplicado == false) {
                                        descricaoDesconto = `| ${formaterDecimalBR(parseFloat(itemDesconto.desconto))} % (Desconto por Qtde)`;
                                        desconto = 1 - (parseFloat(itemDesconto.desconto) / 100);
                                        descontoAplicado = true;
                                    }

                                    if (itemDesconto.descontoBase > 0 && descontoBaseAplicado == false) {
                                        descricaoDescontoBase = `${formaterDecimalBR(parseFloat(itemDesconto.descontoBase))} % (Desconto Base)`;
                                        descontoBase = 1 - (parseFloat(itemDesconto.descontoBase) / 100);
                                        descontoBaseAplicado = true;
                                    }
                                });
                                
                                item.descontoPorQtdePercentual = `${descricaoDescontoBase} ${descricaoDesconto}`

                            }

                            descontoUsuario = 1 - (descontoUsuario / 100);
                            
                            //DESCONTO CAMPANHA DE ESTOQUE
                            let descontoCampanhaEstoque = 1;
                            if (this.listCampanhaEstoque.length > 0) {
                                item.descontoCampanhaEstoque = this.listCampanhaEstoque[0].desconto

                                descontoCampanhaEstoque = 1 - (item.descontoCampanhaEstoque / 100);
                            }

                            //DESCONTO CAMPANHA DE CRÈDITO
                            let descontoCampanhaCredito = 1;
                            if (this.mostrarCampanhaCredito) {
                                item.descontoCampanhaCredito = this.listCampanhaCredito[0].desconto

                                descontoCampanhaCredito = 1 - (item.descontoCampanhaCredito / 100);
                            }
                            
                            //DESCONTO CAMPANHA OURO E PRATA
                            let descontoCampanhaOuroPrata = 1;

                            try {
                                let listCampanhaOuroPrata = await this.consultarCampanhaOuroPrata(item.idMarca)
                                if (listCampanhaOuroPrata.length > 0) {

                                    let itemCampanha = listCampanhaOuroPrata[0];

                                    if (itemCampanha != null && itemCampanha != undefined) {

                                        item.descontoCampanhaOuroPrata = itemCampanha.desconto
                                        item.descontoCampanhaOuroPrataDescricao = itemCampanha.descricao

                                        descontoCampanhaOuroPrata = 1 - (item.descontoCampanhaOuroPrata / 100);
                                    }
                                }
                            }
                            catch(ex) {
                                console.log('Erro ao consultar campanha:', ex);
                            }

                            //DESCONTO DO DISTRIBUIDOR
                            let descontoDistribuidor = 1;
                            if (this.distribuidorConfig != null && this.distribuidorConfig != undefined && this.distribuidorConfig.listDesconto != null && this.distribuidorConfig.listDesconto != undefined && this.distribuidorConfig.listDesconto.length > 0) {

                                const desconto = this.getDescontoDistribuidor(item);
                                descontoDistribuidor = 1 - (formaterDecimalBRServer(desconto) / 100);
                            }

                            let precoSemImpostos = Math.floor(
                                    ( 
                                        (
                                            (
                                                (
                                                    (
                                                        ( 
                                                            ( (precoCusto * descontoBase) * desconto ) * descontoUsuario
                                                        ) 
                                                        * descontoCampanhaEstoque
                                                    ) 
                                                    * descontoCampanhaOuroPrata
                                                ) 
                                                * descontoCampanhaCredito
                                            ) 
                                            * descontoDistribuidor
                                        )
                                    ) * this.og) / this.og;

                            item.valorCertificacaoEX = 0;

                            if (item.certificacaoEX == 1) {
                                const listFamiliaEX = [...this.listCarrinho.filter(car => car.familiaEX == item.familiaEX)];
                                let qtdeFamiliaEX = 0;
                                listFamiliaEX.forEach(itemFamiliaEX => {
                                    qtdeFamiliaEX += parseInt(itemFamiliaEX.qtde);
                                })

                                item.valorCertificacaoEX = Math.floor(((this.configuracaoSistema.valorCertificacaoEX / qtdeFamiliaEX) * this.og)) / this.og;
                                item.valorCertificacaoEXFormatted = `${moedaProduto} ${formaterDecimalBR(item.valorCertificacaoEX)}`;

                                if (qtdeFamiliaEX > this.configuracaoSistema.qtdeMaximaCertificacaoEX) {
                                    this.dialogMessage = {
                                        show: true,
                                        headerText: 'Atenção',
                                        headerColor: 'orange',
                                        headerFontColor: 'white',
                                        bodyText: `A família de produto ${item.familiaEX} possui Certificação EX. <br />
                                                   Não é permitido solicitar qtde acima de ${this.configuracaoSistema.qtdeMaximaCertificacaoEX} unidades para o conjunto de produtos da mesma família.`,
                                        methodOK: ( () => { 

                                            listFamiliaEX.forEach(itemFamiliaEX => {
                                                let itemCarrinho = this.listCarrinho.filter(car => car.codigoProduto == itemFamiliaEX.codigoProduto)[0];

                                                if (itemCarrinho != null && itemCarrinho != undefined) {
                                                    itemCarrinho.qtde = 1;
                                                }
                                            });

                                            this.calcularTotal(showLoading, calculaQtde);
                                        }),
                                    };
                                    
                                    return;
                                }
                            }

                            let precoCustoTotal = 0;

                            if (item.precoAplicadoManualmente == 1) {
                                precoSemImpostos = novoPreco;
                                let qtde = item.qtde

                                if (calculaQtde == false) {
                                    qtde = 1
                                }
                                precoCustoTotal += Math.floor((( precoSemImpostos * qtde) * this.og)) / this.og;
                            }
                            else {
                                item.precoSemImpostosOriginal = `${moedaProduto} ${formaterDecimalBR(precoSemImpostos)}`;
                                precoCustoTotal += Math.floor((( precoSemImpostos * item.qtde) * this.og)) / this.og;
                            }

                            item.descontoAplicado = descontoBase != 1 || desconto != 1 || descontoUsuario != 1 || this.mostrarCampanhas || descontoDistribuidor != 1;

                            item.precoSemImpostos = `${moedaProduto} ${formaterDecimalBR(precoSemImpostos)}`;
                            item.precoUnitarioComImpostos = `${moedaProduto} ${formaterDecimalBR(precoSemImpostos)}`;
                            item.precoCustoTotal = `${moedaProduto} ${formaterDecimalBR(precoCustoTotal)}`;
                            item.margemEfetiva = await this.calcularMargemEfetiva(item);
                            item.moedaProduto = moedaProduto;
                            item.descontoQtde = (1 - parseFloat(desconto) ) * 100;
                            item.descontoQtdeBase = (1 - parseFloat(descontoBase) ) * 100;

                            valorTotal += precoCustoTotal;
                        }
                        else {
                            item.desconto = "0,00"
                        }

                        this.valorTotalSemDescontoCampanha = `${moedaProduto} ${formaterDecimalBR(Math.floor(valorTotal * this.og) / this.og)}`;
                        this.valorTotalFormatado = `${moedaProduto} ${formaterDecimalBR(Math.floor(valorTotal * this.og) / this.og)}`;

                        let custoTotalProdutoComImpostos = parseFloat(formaterDecimalBRServer(item.precoUnitarioComImpostos)) * item.qtde;

                        if (calculaQtde == false) {
                            custoTotalProdutoComImpostos = parseFloat(formaterDecimalBRServer(item.precoUnitarioComImpostos));

                            //Ajustar os Impostos
                            this.ajustarImpostos(item);
                        }

                        item.precoUnitarioTotalComImpostos = `${moedaProduto} ${formaterDecimalBR(Math.floor(custoTotalProdutoComImpostos * this.og) / this.og)}`;

                        ///CALCULO DO VALOR UNITÁRIO
                        let valorTotalItem = this.mostrarBotaoImposto ? parseFloat(formaterDecimalBRServer(item.precoCustoTotal)) : parseFloat(formaterDecimalBRServer(item.precoUnitarioTotalComImpostos));

                        if (item.precoAplicadoManualmente == 1) {

                            valorTotalItem = parseFloat(formaterDecimalBRServer(item.precoCustoTotal));

                            item.precoUnitarioTotalComImpostos = `${moedaProduto} ${formaterDecimalBR(Math.floor(valorTotalItem * this.og) / this.og)}`;
                        }

                        const valorUnitario = Math.floor(((valorTotalItem / item.qtde) * this.og)) / this.og;
                        item.valorUnitario = `${moedaProduto} ${formaterDecimalBR(valorUnitario)}`;

                        if (item.precoAplicadoManualmente == 1) {
                            item.precoCusto = item.valorUnitario;
                            item.precoUnitarioComImpostos = item.valorUnitario;

                            //Ajustar os Impostos
                            this.ajustarImpostos(item);
                        }
                        
                        if (showLoading == true) {
                            this.hideLoading();
                        }
                    }
                    catch (ex) {
                        this.showToast("error", "Aviso!", `Tivemos um problema ao cacular os valores dos produtos, por favor, tente novamente!`);
                        this.impostoCalculado = false;
                        console.log('Erro ao calcular valores no carrinho', ex);
                    }
                });

                if (this.listCarrinho.length <= 0 && showLoading == true) {
                    this.hideLoading();
                }

                this.verificaMoedaItens();
            },

            async ajustarImpostos(item) {
                const precoUnitarioComImpostos = parseFloat(formaterDecimalBRServer(item.precoUnitarioComImpostos));

                const aliqPIS = parseFloat(formaterDecimalBRServer(item.aliqPISFormatted)) / 100;
                const valPIS = (aliqPIS) * precoUnitarioComImpostos;

                const aliqCOFINS = parseFloat(formaterDecimalBRServer(item.aliqCOFINSFormatted)) / 100;
                const valCOFINS = (aliqCOFINS) * precoUnitarioComImpostos;

                const aliqICMS = parseFloat(formaterDecimalBRServer(item.aliqICMSFormatted)) / 100;
                const valICMS = (aliqICMS) * precoUnitarioComImpostos;

                const precoUnitarioComImpostosCalculado = precoUnitarioComImpostos * (1 - aliqPIS - aliqCOFINS) * (1 - aliqICMS);
                item.valPISFormatted = `${item.moedaProduto} ${formaterDecimalBR(Math.floor(valPIS * this.og) / this.og)}`;
                item.valCOFINSFormatted = `${item.moedaProduto} ${formaterDecimalBR(Math.floor(valCOFINS * this.og) / this.og)}`;
                item.valICMSFormatted = `${item.moedaProduto} ${formaterDecimalBR(Math.floor(valICMS * this.og) / this.og)}`;
                item.precoSemImpostosOriginal = `${item.moedaProduto} ${formaterDecimalBR(Math.floor(precoUnitarioComImpostosCalculado * this.og) / this.og)}`;
            },

            async carregarDescontos(limparClienteDistribuidor = true) {
                
                this.listDescontos = [];

                if (limparClienteDistribuidor == true) {
                    this.clienteSelected = undefined;
                    this.distribuidorSelected = undefined;
                }

                let tipoCliente = 2;

                if (this.isUsuarioInterno == true) {
                    
                    if (this.tipoClienteSelected != null && this.tipoClienteSelected != undefined) {
                        tipoCliente = this.tipoClienteSelected.id;

                        this.listCarrinho.forEach(item => {

                            if (this.listProdutoKeyAccount != null && this.listProdutoKeyAccount != undefined && this.listProdutoKeyAccount.length > 0) {
                                let existeProdutoKeyAccount = this.listProdutoKeyAccount.filter(key => key.codigoProduto.toString().trim() == item.codigoProduto.toString().trim());

                                if (existeProdutoKeyAccount == null || existeProdutoKeyAccount == undefined || existeProdutoKeyAccount.length <= 0) {
                                    if (this.listProdutoKeyAccount[0].tipoCliente != null && this.listProdutoKeyAccount[0].tipoCliente != undefined && this.listProdutoKeyAccount[0].tipoCliente != 0) {
                                        tipoCliente = this.listProdutoKeyAccount[0].tipoCliente;
                                    }
                                }
                            }
                        })
                    }
                }

                let listIDsProduto = this.listCarrinho.map(car => car.idProduto)

                let descontoFilter = {
                    tipoCliente,
                    listIDsProduto
                }

                this.listDescontos = await this.$store.dispatch("descontoModule/ListTipoClienteProduto", descontoFilter);

                this.calcularTotal();
                this.consultarCampanhas();
            },

            async descontoUsuarioPermitido(valorDesconto, validaDescontoZerado = true) {

                if (valorDesconto <= 0 && validaDescontoZerado) {
                    
                    this.showToast("error", "Aviso!", `Desconto não informado!`);
                    return false;
                }
                else {
                    return true;
                }
            },

            async confirmarDescontoUsuario() {

                let descontoUsuario = formaterDecimalBRServer(this.descontoUsuarioFormatado)

                if (await this.descontoUsuarioPermitido(descontoUsuario)) {
                    this.dialog = {
                        show: true,
                        headerText: 'Confirmação',
                        bodyText: `Você irá aplicar ${this.descontoUsuarioFormatado}% de desconto a todos os produtos do carrinho, confirma sua decisão?`,
                        methodAcao1: ( () => {} ),
                        methodConfirm: this.aplicarDescontoUsuario,
                        params: 0
                    };
                }
            },

            getDescontoDistribuidor(itemProduto) {

                let desconto = "0,00";

                if (this.distribuidorConfig != null && this.distribuidorConfig != undefined &&
                    this.distribuidorConfig.listDesconto != null && this.distribuidorConfig.listDesconto != undefined && this.distribuidorConfig.listDesconto.length > 0) {

                    let descontoDist = this.distribuidorConfig.listDesconto.filter(des => des.marcaSelected.id == itemProduto.idMarca)[0];

                    if (descontoDist != null && descontoDist != undefined) {
                        desconto = descontoDist.descontoFormatted;
                    }
                }

                return desconto;
            },

            async aplicarDescontoUsuario() {

                this.listCarrinho.forEach(async item => {
                    item.desconto = this.descontoUsuarioFormatado
                })

                await this.calcularTotal();
            },

            async validarCotacao() {
                
                let idTipoCliente = 0;
                let idCondicaoPagamento = 0;
                let idFormaPagamento = 0;
                let idTipoFrete = 0;
                let mostrarMensagemConfirmacao = true;

                if (this.isUsuarioInterno == true) {
                    if (this.tipoClienteSelected != null && this.tipoClienteSelected != undefined) {
                        idTipoCliente = this.tipoClienteSelected.id;
                    }
                    
                    if (this.tipoFreteSelected != null && this.tipoFreteSelected != undefined) {
                        idTipoFrete = this.tipoFreteSelected.id;
                    }
                }
                else {
                    idTipoCliente = 2; //Distribuidor
                    idTipoFrete = 1; //FOB
                }

                if (this.condicaoPagamentoSelected != null && this.condicaoPagamentoSelected != undefined) {
                    idCondicaoPagamento = this.condicaoPagamentoSelected.id;
                }

                if (this.formaPagamentoSelected != null && this.formaPagamentoSelected != undefined) {
                    idFormaPagamento = this.formaPagamentoSelected.id;
                }

                if (idTipoCliente == 0) {

                    this.showToast("error", "Aviso!", `Tipo de Cliente não informada!`);
                    mostrarMensagemConfirmacao = false;
                }
                else if (idCondicaoPagamento == 0) {

                    this.showToast("error", "Aviso!", `Condição de Pagamento não informada!`);
                    mostrarMensagemConfirmacao = false;
                }
                else if (idFormaPagamento == 0) {

                    this.showToast("error", "Aviso!", `Forma de Pagamento não informada!`);
                    mostrarMensagemConfirmacao = false;
                }
                else if (idTipoFrete == 0) {

                    this.showToast("error", "Aviso!", `Tipo de Frete não informado!`);
                    mostrarMensagemConfirmacao = false;
                }
                else {
                    
                    let listCodigoProduto = [];
                    let msgDataEntregaInvalida = '';
                    let msgProdutoAgrupado = '';
                    const dataAtual = moment();
                    const anoAtual = moment(new Date()).format('YYYY');
                    let index = 1;
                    this.listCarrinho.forEach(async item => {
                        
                        const margemMinimaIsValid = await this.checkMargemMinima(item);

                        if (margemMinimaIsValid == false) {
                            mostrarMensagemConfirmacao = false;
                        }

                        if (item.showDialogFaturamentoParcial == true) {
                            listCodigoProduto.push(item)
                        }

                        if (item.entregaProgramada == 1) {

                            const dataEntregaValida = moment(formatDateToServer(item.dataEntrega));

                            if (!dataEntregaValida.isValid()) {
                                msgDataEntregaInvalida += `${item.codigoProduto} - Data ${item.dataEntregaFormatted} inválida <br />`;
                            }

                            if (dataAtual.isAfter(dataEntregaValida)) {
                                msgDataEntregaInvalida += `${item.codigoProduto} - Data ${item.dataEntregaFormatted} não pode ser menor que hoje <br />`;
                            }

                            if (this.userLoggedGetters.perfil.dataEntregaAnoSuperior != 1) {
                                const anoDataEntrega = dataEntregaValida.format('YYYY');

                                if (parseInt(anoDataEntrega) > anoAtual) {
                                    msgDataEntregaInvalida += `${item.codigoProduto} - Data ${item.dataEntregaFormatted} deve ser do ano atual <br />`;
                                }
                            }
                        }
                        
                        if (item.produtoAgrupado == 1 && (item.tagAgrupamento == null || item.tagAgrupamento == undefined || item.tagAgrupamento.toString().trim() == '') ) {
                            msgProdutoAgrupado += `Tag de agrupamento do produto ${item.codigoProduto} não pode ser vazia <br />`;
                        }
                        
                        if (this.listCarrinho.length == index) {
                            if (msgDataEntregaInvalida != null && msgDataEntregaInvalida != undefined && msgDataEntregaInvalida != '') {
                                mostrarMensagemConfirmacao = false;

                                this.showToast("error", "Aviso!", `Há inconsistências no(s) produto(s) abaixo referente a data de entrega: <br /><br /> ${msgDataEntregaInvalida}`);
                            }

                            if (msgProdutoAgrupado != null && msgProdutoAgrupado != undefined && msgProdutoAgrupado != '') {
                                mostrarMensagemConfirmacao = false;

                                this.showToast("error", "Aviso!", `Há inconsistências no(s) produto(s) abaixo referente ao agrupamento : <br /><br /> ${msgProdutoAgrupado}`);
                            }
                        }

                        index++;
                    })

                    if (listCodigoProduto.length > 0) {
                        mostrarMensagemConfirmacao = false;

                        listCodigoProduto.forEach(item => {
                            this.dialog = {
                                show: true,
                                headerText: 'Confirmação',
                                button1Text: "NÃO",
                                button2Text: "SIM",
                                bodyText: `O produto [${item.codigoProduto}] não tem estoque suficiente, aceita o faturamento parcial?`,
                                methodAcao1: ( () => { item.faturamentoParcial = 0; item.showDialogFaturamentoParcial = false; } ),
                                methodConfirm: ( () => { item.faturamentoParcial = 1; item.showDialogFaturamentoParcial = false; } ),
                                params: 0
                            };
                        })

                        const checkProduto = setTimeout( () => {

                            if (listCodigoProduto.length <= 0) {
                                this.confirmacaoGravacaoCotacao();
                                clearInterval(checkProduto);
                            }
                            else {
                                this.validarCotacao();
                            }
                        }, 1000 )
                    }
                }

                if (await this.verificaItensComCertificacaoEX() == false) {
                    mostrarMensagemConfirmacao = false;
                }
                
                if (mostrarMensagemConfirmacao) {
                    this.confirmacaoGravacaoCotacao();
                }
            },

            async confirmacaoGravacaoCotacao() {
                this.dialog = {
                    show: true,
                    headerText: 'Confirmação',
                    bodyText: `Você irá gerar uma nova cotação, confirma sua decisão?`,
                    methodAcao1: ( () => {} ),
                    methodConfirm: this.gravarCotacao,
                    params: 0
                };
            },

            async verificaItensComCertificacaoEX() {
                let listItensRequest = [];
                this.listCarrinho.map( item => { listItensRequest.push( {codigoProduto: item.codigoProduto.toString().trim() }); });
                
                const listItensCertificacaoEX = await this.$store.dispatch("pedidoModule/VerificarCertificacaoEX", listItensRequest);

                if (listItensCertificacaoEX != null && listItensCertificacaoEX != undefined && listItensCertificacaoEX.length > 0) {

                    let detalhesProdutosCertEX = `<table style='width: 100%; border: none; padding: 10px;>'
                                                    <th>
                                                        <td style="text-align: left">Produto</td>
                                                        <td style="text-align: left">Quando poderá ser solicitado</td>
                                                    </th>`;

                    listItensCertificacaoEX.forEach (item => {
                        detalhesProdutosCertEX += `<tbody>
                                                        <td style="text-align: left">${item.codigoProduto}</td>
                                                        <td style="text-align: left">${item.novaDataPedidoFormatted}</td>
                                                   </tbody>`;
                    });

                    this.dialogMessage = {
                        show: true,
                        headerText: 'Atenção',
                        headerColor: 'orange',
                        headerFontColor: 'white',
                        bodyText: `Não será permitido efetuar a cotação pois há itens com certificação EX pedidos anteriormente e que ainda não podem ser solicitados, segue detalhes abaixo: <br /> <br />
                                  ${detalhesProdutosCertEX}`,
                        methodOK: ( () => { }),
                    };
                    return false;
                }

                return true
            },

            async gravarCotacao() {

                this.showLoading();

                let idCliente = 0;
                let idCondicaoPagamento = 0;
                let idFormaPagamento = 0;
                let idTipoFrete = 0;
                let idTipoCliente = 0;
                let tipoPedido = ''

                if (this.isUsuarioInterno == true || this.temDistribuidorPai) {
                    
                    if (this.tipoClienteSelected != null && this.tipoClienteSelected != undefined) {
                        idTipoCliente = this.tipoClienteSelected.id;

                        if (this.tipoClienteSelected.id == 5) {
                            idCliente = this.clienteSelected.id;
                        }
                    }
                    
                    if (idCliente == 0) {
                        if (this.distribuidorSelected != null && this.distribuidorSelected != undefined) {
                            idCliente = this.distribuidorSelected.id;
                        }
                        else if (this.clienteSelected != null && this.clienteSelected != undefined) {
                            idCliente = this.clienteSelected.id;
                        }
                    }
                    
                    if (this.tipoFreteSelected != null && this.tipoFreteSelected != undefined) {
                        idTipoFrete = this.tipoFreteSelected.id;
                    }

                    if (this.tipoPedidoSelected != null && this.tipoPedidoSelected != undefined) {
                        tipoPedido = this.tipoPedidoSelected.id;
                    }

                    if (this.temDistribuidorPai == true) {
                        idTipoCliente = 2;
                    }
                }
                else {

                    idTipoCliente = 2; //Distribuidor
                    idTipoFrete = 1; //FOB
                    tipoPedido = 'R' //REVENDEDOR 
                    
                    if (this.tipoFreteSelected != null && this.tipoFreteSelected != undefined) {
                        idTipoFrete = this.tipoFreteSelected.id;
                    }
                    
                    idCliente = this.userLoggedGetters.distribuidorSelected.id;
                }

                if (this.condicaoPagamentoSelected != null && this.condicaoPagamentoSelected != undefined) {
                    idCondicaoPagamento = this.condicaoPagamentoSelected.id;
                }

                if (this.formaPagamentoSelected != null && this.formaPagamentoSelected != undefined) {
                    idFormaPagamento = this.formaPagamentoSelected.id;
                }

                let listItemsOrcamento = [];

                this.listCarrinho.forEach(item => {

                    let prazoEntrega = 0;

                    if (item.prazoEntrega != this.consultarFabricaDescricao) {
                        prazoEntrega = item.prazoEntrega;
                    }

                    let descontoUsuario = formaterDecimalBRServer(item.desconto);

                    if (item.precoAplicadoManualmente == 1) {
                        descontoUsuario = 0;
                    }

                    let entregaProgramada = 0;
                    let dataEntrega = null;

                    if (item.entregaProgramada != null && item.entregaProgramada != undefined) {
                        entregaProgramada = item.entregaProgramada;

                        if (entregaProgramada == 1) {
                            dataEntrega = formatDateToServer(item.dataEntregaFormatted);
                        }
                    }

                    listItemsOrcamento.push({
                        id: 0,
                        idProduto: item.idProduto,
                        imagem: item.imagem,
                        codigoProduto: item.codigoProduto,
                        descricaoProduto: item.descricao,
                        listaIDParametroItem: item.listaIDParametroItem,
                        qtde: item.qtde,
                        prazoEntrega: prazoEntrega,
                        ncm: item.codigoNCM,
                        valorCustoProduto: item.precoCustoProdutoSemMargem,
                        precoSemImpostos: item.precoCusto,
                        descontoUsuario: descontoUsuario,
                        descontoCampanhaEstoque: item.descontoCampanhaEstoque,
                        descontoCampanhaCredito: this.mostrarCampanhaCredito == true ? item.descontoCampanhaCredito : 0,
                        descontoCampanhaOuroPrata: item.descontoCampanhaOuroPrata,
                        descontoQtde: item.descontoQtde,
                        descontoQtdeBase: item.descontoQtdeBase,
                        aliqPIS: item.aliqPISFormatted,
                        aliqCOFINS: item.aliqCOFINSFormatted,
                        aliqICMS: item.aliqICMSFormatted,
                        aliqIPI: item.aliqIPIFormatted,
                        precoBruto: item.precoUnitarioComImpostos,
                        valorTotal: item.precoCustoTotal,
                        keyAccount: item.keyAccount,
                        faturamentoParcial: item.faturamentoParcial,
                        precoAplicadoManualmente: item.precoAplicadoManualmente,
                        entregaProgramada: entregaProgramada,
                        dataEntrega: dataEntrega,
                        idCambioICP: item.idCambioICP,
                        valorTotalICP: item.valorTotalICP,
                        certificacaoEX: item.certificacaoEX,
                        valorCertificacaoEX: item.valorCertificacaoEX,
                        produtoAgrupado: item.produtoAgrupado,
                        tagAgrupamento: item.tagAgrupamento,
                        observacao: item.observacao
                    })
                });

                const abaterSaldoCampanhaCredito = this.elegivelParaCampanha == true && this.abaterSaldoCampanhaCredito == true ? 1 : 0;
                const entregaTurbo = this.entregaTurbo == 1 || this.entregaTurbo == true ? 1 : 0;

                let orcamentoRequest = {
                    id: 0,
                    status: 1, //Rascunho
                    idCliente: idCliente,
                    idTipoCliente: idTipoCliente,
                    idCondicaoPagamento: idCondicaoPagamento,
                    idFormaPagamento: idFormaPagamento,
                    idTipoFrete: idTipoFrete,
                    tipoPedido: tipoPedido,
                    descontoUsuario: this.descontoUsuarioFormatado,
                    descontoCampanhaEstoque: this.listCarrinho[0].descontoCampanhaEstoque,
                    abaterSaldoCampanhaCredito,
                    entregaTurbo,
                    listItemsOrcamento: listItemsOrcamento,
                }
                
                const result = await this.$store.dispatch("orcamentoModule/CreateUpdate", orcamentoRequest);

                if (result.success === true) {
                    this.showToast("success", "Sucesso!", `Cotação #${result.idOrcamento} gerada com sucesso!`);
                    this.limparCarrinho();
                    this.$router.push({ name: "cotacao" });
                }
                else {
                    this.showToast("error", "Aviso!", result.message);
                    this.hideLoading();
                }
            },

            getMargemEfetiva(itemProduto) {
                const precoCustoSemMargem = parseFloat(itemProduto.precoCustoProdutoSemMargem) * itemProduto.qtde;
                const precoSemImpostos = parseFloat(formaterDecimalBRServer(itemProduto.precoSemImpostos)) * itemProduto.qtde;

                return Math.round(Math.floor((((precoSemImpostos - precoCustoSemMargem) / precoSemImpostos) * 100) * this.og) / this.og);
            },

            async checkMargemMinima(itemProduto) {

                let isValid = true;

                const margemEfetiva = this.getMargemEfetiva(itemProduto);

                const margemMinima = itemProduto.importado == 1 ? parseFloat(this.userLoggedGetters.perfil.margemMinimaProdutoImportado) : parseFloat(this.userLoggedGetters.perfil.margemMinimaProdutoNacional);

                if (this.isUsuarioInterno == true &&
                    this.isTipoKeyAccount == false &&
                    margemEfetiva < margemMinima && 
                    (
                        itemProduto.mensagemMargemMinimaApresentada == null || 
                        itemProduto.mensagemMargemMinimaApresentada == undefined || 
                        itemProduto.mensagemMargemMinimaApresentada == false
                    )
                   ) {

                    isValid = false;

                    this.dialogMessage = {
                        show: true,
                        headerText: 'Atenção',
                        headerColor: 'orange',
                        headerFontColor: 'white',
                        bodyText: `A margem efetiva de <strong>[${margemEfetiva}% do Produto: ${itemProduto.codigoProduto}]</strong> para o desconto aplicado é menor que o permitido <strong>[${margemMinima}%]</strong> para o seu perfil.
                                    <br /> 
                                    Favor entrar em contato com seu supervisor direto!`,
                        methodOK: (async () => { 
                            itemProduto.desconto = "0,00";
                            itemProduto.mensagemMargemMinimaApresentada = true;
                            await this.calcularTotal(false);
                        }),
                    };
                }

                itemProduto.mensagemMargemMinimaApresentada = false;

                return isValid;
            },

            calcularMargemEfetiva(itemProduto) {

                const margemEfetiva = this.getMargemEfetiva(itemProduto);

                this.checkMargemMinima(itemProduto);

                return `${formaterDecimalBR(margemEfetiva)} %` ;
            },

            async consultarCampanhaOuroPrata(idMarca) {
                
                let idDistribuidor = "";
                let estado = "";

                if (this.isUsuarioInterno == true) {
                    
                    if (this.distribuidorSelected != null && this.distribuidorSelected != undefined) {
                        idDistribuidor = this.distribuidorSelected.id;
                        estado = this.distribuidorSelected.state;
                    }
                }
                else {
                    idDistribuidor = this.userLoggedGetters.distribuidorSelected.id;
                    estado = this.userLoggedGetters.distribuidorSelected.state;
                }

                const campanhaOuroPrataParticipacaoFilter = {
                    idDistribuidor,
                    idMarca,
                    estado
                }

                const campanhaJahConsultada = this.listCampanhaOuroPrataConsultada.filter (cam => cam.idMarca == idMarca && cam.idDistribuidor == idDistribuidor)[0];

                if (campanhaJahConsultada == null || campanhaJahConsultada == undefined || campanhaJahConsultada.length <= 0) {
                    this.listCampanhaOuroPrata = await this.$store.dispatch("campanhaOuroPrataModule/ConsultarParticipacao", campanhaOuroPrataParticipacaoFilter);
                    this.listCampanhaOuroPrataConsultada.push({
                        idMarca,
                        idDistribuidor,
                        listCampanhaOuroPrata: this.listCampanhaOuroPrata
                    })
                }
                else {
                    this.listCampanhaOuroPrata = campanhaJahConsultada.listCampanhaOuroPrata;
                }

                return this.listCampanhaOuroPrata;
            },

            async validarCampanhaCredito(showLoading, calcularTotais = true) {

                if (this.mostrarBotaoConsultaSaldoCampanha == true) {

                    if (showLoading == true) {
                        this.showLoading();
                    }

                    let idDistribuidor = "";
                    this.listCampanhaCredito = [];

                    if (this.distribuidorSelected != null && this.distribuidorSelected != undefined) {
                        idDistribuidor = this.distribuidorSelected.id;
                    }
                    else if (this.userLoggedGetters.usuarioInterno != 1) {
                        idDistribuidor = this.userLoggedGetters.distribuidorSelected.id;
                    }

                    const resultSaldoTotalCampanhaCredito = await this.$store.dispatch("campanhaCreditoModule/ConsultarSaldoCampanhaTotvs", idDistribuidor);
                    const valorSaldoCampanha = resultSaldoTotalCampanhaCredito.result;
                    const totalCotacao = parseFloat(formaterDecimalBRServer(this.valorTotalFormatado)) + parseFloat(this.valorTotalIPI);

                    const possuiSaldoCampanha = valorSaldoCampanha > 0 && 
                                                valorSaldoCampanha > totalCotacao;

                    this.elegivelParaCampanha = possuiSaldoCampanha ||
                                                this.abaterSaldoCampanhaCredito == true;

                    this.disableSaldoCampanhaCredito = possuiSaldoCampanha == false;

                    if (valorSaldoCampanha <= 0 || this.elegivelParaCampanha == false) {
                        this.abaterSaldoCampanhaCredito = false;
                    }
                    else if (this.disableSaldoCampanhaCredito == true) {
                        this.abaterSaldoCampanhaCredito = false;
                    }

                    if (this.elegivelParaCampanha == true && this.abaterSaldoCampanhaCredito == true) {
                        
                        let campanhaCreditoParticipacaoFilter = {
                            idDistribuidor
                        }
                        
                        this.listCampanhaCredito = await this.$store.dispatch("campanhaCreditoModule/ConsultarParticipacao", campanhaCreditoParticipacaoFilter);
                    }

                    this.setCondicaoFormaPgto();

                    if (showLoading == true) {
                        
                        if (calcularTotais == true) {
                            await this.calcularTotal();
                        }

                        this.hideLoading();
                    }
                }
            },
            
            async consultarCampanhas() {

                let idDistribuidor = "";
                let idCondicaoPagamento = 0;
                let estado = "";
                let valor = parseFloat(formaterDecimalBRServer(this.valorTotalSemDescontoCampanha));
                this.distribuidorConfig = null;
                this.listCampanhaCredito = [];
                this.listCondicaoPagamento = [];
                this.abaterSaldoCampanhaCredito = true;

                if (this.distribuidorSelected != null && this.distribuidorSelected != undefined) {
                    idDistribuidor = this.distribuidorSelected.id;
                    estado = this.distribuidorSelected.state;
                }
                else if (this.userLoggedGetters.usuarioInterno != 1) {
                    idDistribuidor = this.userLoggedGetters.distribuidorSelected.id;
                    estado = this.userLoggedGetters.distribuidorSelected.state;
                }

                if (this.condicaoPagamentoSelected != null && this.condicaoPagamentoSelected != undefined) {
                    idCondicaoPagamento = this.condicaoPagamentoSelected.id;
                }

                let campanhaEstoqueParticipacaoFilter = {
                    idDistribuidor,
                    idCondicaoPagamento,
                    estado,
                    valor
                }

                this.listCampanhaEstoque = await this.$store.dispatch("campanhaEstoqueModule/ConsultarParticipacao", campanhaEstoqueParticipacaoFilter);

                if (idDistribuidor != "") {

                    this.loadingCondicaoPagamento = true;

                    let resultDistribuidor = await this.$store.dispatch("distribuidorConfigModule/GetByDistribuidor", idDistribuidor);

                    this.distribuidorConfig = resultDistribuidor.result;

                    await this.validarCampanhaCredito(false);

                    if (this.distribuidorConfig.listaCondicaoPagamento != null && this.distribuidorConfig.listaCondicaoPagamento != undefined && this.distribuidorConfig.listaCondicaoPagamento != '') {
                        try {
                            this.listCondicaoPagamento = JSON.parse(this.distribuidorConfig.listaCondicaoPagamento);
                        }
                        catch {
                            this.listCondicaoPagamento = [];
                        }
                    }

                    this.loadingCondicaoPagamento = false;
                }

                if (this.isUsuarioInterno == true) {
                    this.listCondicaoPagamento = await this.$store.dispatch("condicaoPagamentoModule/List");
                }

                if (this.listCondicaoPagamento == null || this.listCondicaoPagamento == undefined || this.listCondicaoPagamento.length <= 0 ) {
                    this.listCondicaoPagamento.push ({
                        id: 1,
                        descricao: "À VISTA"
                    })
                }

                let existeCondicaoPagamentoNaLista = this.listCondicaoPagamento.filter(con => con.id == idCondicaoPagamento);

                if (existeCondicaoPagamentoNaLista == null || existeCondicaoPagamentoNaLista == undefined || existeCondicaoPagamentoNaLista.length <= 0) {
                    this.condicaoPagamentoSelected = null;
                }

                this.setCondicaoFormaPgto();

                await this.calcularTotal();
            },

            async confirmarCalculoImpostos() {

                let mostrarConfirmacao = true;
                
                if (this.isUsuarioInterno == true) {

                    if (this.tipoClienteSelected == null || this.tipoClienteSelected == undefined) {
                        
                        this.showToast("error", "Aviso!", "É necessário selecionar um tipo de cliente");
                        mostrarConfirmacao = false;
                    }
                    else if (this.tipoClienteSelected.id == 2 && (this.distribuidorSelected == null || this.distribuidorSelected == undefined)) {
                        
                        this.showToast("error", "Aviso!", "É necessário selecionar um distribuidor");
                        mostrarConfirmacao = false;
                    }
                    else if (this.tipoClienteSelected.id != 2 && (this.clienteSelected == null || this.clienteSelected == undefined)) {
                        
                        this.showToast("error", "Aviso!", "É necessário selecionar um cliente");
                        mostrarConfirmacao = false;
                    }
                }

                if (mostrarConfirmacao) {

                    this.dialog = {
                        show: true,
                        headerText: 'Confirmação',
                        bodyText: `Você irá calcular os impostos, confirma sua decisão?`,
                        methodAcao1: ( () => {} ),
                        methodConfirm: this.gerarImpostos,
                        params: 0
                    };
                }
            },

            async gerarImpostos() {

                this.showLoading();

                await this.calcularTotal(false);

                var valorTotal = 0;

                let qtdeTotalProdutos = 1;

                this.valorTotalIPI = 0;
                
                await this.listCarrinho.forEach(async item => {

                    const custoTotal = parseFloat(formaterDecimalBRServer(item.precoCustoTotal));
                    const custoProduto = parseFloat(formaterDecimalBRServer(item.precoSemImpostos));
                    
                    const custoProdutoComImpostos = await this.calcularImpostos(item, custoProduto);
                    const custoTotalComImpostos = await this.calcularImpostos(item, custoTotal);

                    const custoTotalProdutoComImpostos = parseFloat(custoProdutoComImpostos) * item.qtde;

                    item.precoCustoTotal = `${item.moedaProduto} ${formaterDecimalBR(custoTotal)}`;
                    item.precoUnitarioComImpostos = `${item.moedaProduto} ${formaterDecimalBR(Math.floor(custoProdutoComImpostos * this.og) / this.og)}`;
                    item.precoUnitarioTotalComImpostos = `${item.moedaProduto} ${formaterDecimalBR(Math.floor(custoTotalProdutoComImpostos * this.og) / this.og)}`;

                    valorTotal += custoTotalComImpostos + (item.valorCertificacaoEX * item.qtde);

                    this.valorTotalSemDescontoCampanha = `${item.moedaProduto} ${formaterDecimalBR(Math.floor(valorTotal * this.og) / this.og)}` ;
                    this.valorTotalFormatado = `${item.moedaProduto} ${formaterDecimalBR(Math.floor(valorTotal * this.og) / this.og)}`;
                    this.valorTotalIPI += item.valorIPI;

                    if (qtdeTotalProdutos == this.listCarrinho.length) {
                        this.hideLoading();
                        this.impostoCalculado = true;
                        await this.verificarAnaliseCredito();
                        await this.validarCampanhaCredito(true, false);
                    }

                    if (item.compraMinima == 1 && 
                        parseInt(item.qtde) > item.qtdeEstoque) {
                        this.showToast("warning", "Aviso!", `O produto [${item.codigoProduto}] possui [${item.qtdeEstoque}un] em estoque e uma qtde mínima de [${item.compraMinimaQtde}un] configurada.`);
                    }

                    qtdeTotalProdutos++;
                });
            },

            setTipoPedido() {
                
                let type = "";

                if (this.tipoClienteSelected != null && this.tipoClienteSelected != undefined && this.tipoClienteSelected.id == 2) {
                    type = this.distribuidorSelected.type;
                }
                else {
                    type = this.clienteSelected.type;
                }

                let tipoPedido = this.listTipoPedido.filter(tpd => tpd.id == type)[0];

                if (tipoPedido != null && tipoPedido != undefined) {
                    this.tipoPedidoSelected = tipoPedido;
                }

            },

            showDialogPrazoEntrega(itemProduto) {
                this.dialogPrazoEntrega = {
                    show: true,
                    prazoEntrega: itemProduto.prazoEntrega,
                    prazoEntregaOriginal: itemProduto.prazoEntregaOriginal,
                    methodConfirm: (novoPrazoEntrega) => {
                        itemProduto.prazoEntrega = novoPrazoEntrega;
                    }
                }
            },

            showDialogValorItem(itemProduto) {

                this.dialogValorItem = {
                    show: true,
                    id: Math.random(),
                    codigoProduto: itemProduto.codigoProduto,
                    precoLista: "0",
                    valor: itemProduto.precoUnitarioTotalComImpostos,
                    methodConfirm: (novoValorItem) => {

                        let custoOriginal = parseFloat(formaterDecimalBRServer(itemProduto.precoCustoTotal));
                        let desconto = ( (custoOriginal - parseFloat(formaterDecimalBRServer(novoValorItem))) / custoOriginal) * 100;

                        desconto = Math.floor(desconto * this.og) / this.og;

                        let novoPreco = `${itemProduto.moedaProduto} ${novoValorItem}`;

                        itemProduto.precoAplicadoManualmente = 1;
                        itemProduto.precoCusto = novoPreco;
                        itemProduto.novoPreco = novoPreco;
                        itemProduto.desconto = formaterDecimalBR(desconto);

                        this.calcularTotal(true, false);
                    }
                }
            },

            async recarregarCarrinho() {
                this.showLoading();

                this.getLists();
                await this.carregarCarrinho();

                if (this.userLoggedGetters.usuarioInterno != 1) {
                    this.carregarDescontos();
                }
            },

            abrirDialogSaldoCampanha() {

                let idDistribuidor = "0";

                if (this.isUsuarioInterno == true) {
                    if (this.distribuidorSelected != null && this.distribuidorSelected != undefined) {
                        idDistribuidor = this.distribuidorSelected.id;
                    }
                }
                else {
                    idDistribuidor = this.userLoggedGetters.distribuidorSelected.id;
                }                

                this.dialogSaldoCampanha = {
                    show: true,
                    idAux: Math.random(),
                    idDistribuidor
                }
            },

            async clonarItem(itemProduto) {
                const response = await this.$store.dispatch("carrinhoModule/CreateUpdate", { itemProduto });

                if (response.success) {
                    this.carrinhoStateGetters.reload = !this.carrinhoStateGetters.reload;
                    this.showToast("success", "Sucesso!", response.message);
                    await this.calcularTotal(true);
                    this.validarCampanhaCredito(true);
                }
            },

            async formatarData(itemProduto) {
                itemProduto.dataEntregaFormatted = formatarDataPadraoPtBR(itemProduto.dataEntrega);
                itemProduto.mnuDataEntrega = false;
            },

            async verificarAnaliseCredito() {

                if (this.isUsuarioInterno == true) {

                    let idCliente = "0";
                    let nomeCliente = "";

                    if (this.tipoClienteSelected != null && this.tipoClienteSelected != undefined) {
                        
                        if (this.tipoClienteSelected.id == 2) {
                            if (this.distribuidorSelected != null && this.distribuidorSelected != undefined) {
                                idCliente = this.distribuidorSelected.id;
                                nomeCliente = this.distribuidorSelected.nameAndCnpj;
                            }
                        }
                        else {
                            if (this.clienteSelected != null && this.clienteSelected != undefined) {
                                idCliente = this.clienteSelected.id;
                                nomeCliente = this.clienteSelected.nameAndCnpj;
                            }
                        }
                    }

                    const totalCotacao =  parseFloat(formaterDecimalBRServer(this.valorTotalFormatado));
                    const listConfigAnaliseCredito = await this.$store.dispatch("analiseCreditoModule/List");

                    const analiseCredito = listConfigAnaliseCredito.filter(config => totalCotacao >= config.valorInicial && totalCotacao <= config.valorFinal && config.bloqueia == 1)[0];

                    if (analiseCredito != null && analiseCredito != undefined) {

                        const resultAnaliseCredito = await this.$store.dispatch("analiseCreditoModule/GetCreditoCliente", idCliente);

                        if (resultAnaliseCredito != null && resultAnaliseCredito != undefined) {

                            if (resultAnaliseCredito.limiteCredito < totalCotacao) {
                                this.showAnaliseCredito = true;
                                this.dadosAnaliseCredito = {
                                    idUsuarioSolicitante: this.userLoggedGetters.id,
                                    nomeCliente,
                                    limiteCredito: `R$ ${formaterDecimalBR(resultAnaliseCredito.limiteCredito)}`,
                                    totalCotacao: this.valorTotalFormatado,
                                    descricao: analiseCredito.descricao,
                                    descricaoAnaliseDias: analiseCredito.descricaoAnaliseDias
                                }
                            }
                        }
                    }
                }
            },

            async abrirDialogAnaliseCredito() {
                
                this.dialogAnaliseCredito = {
                    show: true,
                    dadosAnaliseCredito: this.dadosAnaliseCredito,
                }
            },

            async verificaMoedaItens() {

                if (this.listCarrinho != null && this.listCarrinho != undefined && this.listCarrinho.length > 0) {
                    const moedaPrimeiroItem = this.listCarrinho[0].moedaProduto;
                    let moedaDiferente = false;

                    this.listCarrinho.forEach(itemCarrinho => {
                        if (moedaPrimeiroItem != itemCarrinho.moedaProduto) {
                            moedaDiferente = true;
                        }
                    });                    

                    setTimeout(() => {

                        if (moedaDiferente == true) {

                            this.dialogMessage = {
                                show: true,
                                headerText: 'Atenção',
                                headerColor: 'orange',
                                headerFontColor: 'white',
                                bodyText: `Há itens com moedas diferentes de ${moedaPrimeiroItem} no carrinho. <br />
                                           Aconselhamos montar uma outra cotação com esses itens. <br /> </br >
                                           O sistema irá excluir automaticamente os itens com moeda diferente de ${moedaPrimeiroItem}!`,
                                methodOK: (() => { this.excluirItensMoedaDiferente(moedaPrimeiroItem); }),
                            };
                        }
                    }, 500);                    
                }
            },

            async excluirItensMoedaDiferente(moedaPrimeiroItem) {

                this.showLoading();
                
                this.listCarrinho.forEach(itemCarrinho => {
                    if (moedaPrimeiroItem != itemCarrinho.moedaProduto) {
                        this.$store.dispatch("carrinhoModule/ExcluirItem", itemCarrinho);
                    }
                });

                setTimeout(() => {
                    this.carrinhoStateGetters.reload = !this.carrinhoStateGetters.reload;
                    this.carregarCarrinho();
                    this.hideLoading();
                }, 500);
            },

            showDialogNovoCliente() {
                this.dialogCliente = {
                    show: true,
                    idAux: Math.random(),
                    methodConfirm: async (cpfCnpj) => {
                        await this.loadClientes();

                        const cpfCnpjClear = cpfCnpj.toString().replaceAll(".", "").replaceAll("/", "").replaceAll("-", "");

                        const clienteSelected = this.listClientesFiltered.filter(cli => cli.cnpjProcessed == cpfCnpjClear)[0];

                        if (clienteSelected != null && clienteSelected != undefined) {
                            this.clienteSelected = clienteSelected;
                            this.$forceUpdate();
                        }
                    }
                }
            },

            async setCondicaoFormaPgto() {

                if (this.abaterSaldoCampanhaCredito == 1 && this.isTipoDistribuidor == true) {
                    const condicaoPagamentoAVista = this.listCondicaoPagamento.filter (cond => cond.descricao.toString().includes("VISTA"))[0];
                    this.condicaoPagamentoSelected = condicaoPagamentoAVista;

                    const formaPagamentoDeposito = this.listFormaPagamento.filter (form => form.descricao.toString().includes("DEP"))[0];
                    this.formaPagamentoSelected = formaPagamentoDeposito;
                }
                else if (this.impostoCalculado == false) {
                    this.condicaoPagamentoSelected = null;
                    this.formaPagamentoSelected = null;
                }
            },

            async aplicarEntregaTurbo() {

                if (this.listCarrinho != null && 
                    this.listCarrinho != undefined && 
                    this.listCarrinho.length > 0) {
                    let carrinhoValido = true;

                    this.listCarrinho.forEach(itemCarrinho => {
                        if (itemCarrinho.importado == 1) {
                            carrinhoValido = false;
                        }
                    });                    

                    setTimeout(() => {

                        if (this.entregaTurbo == 1) {

                            if (carrinhoValido == false) {

                                this.dialogMessage = {
                                    show: true,
                                    headerText: 'Atenção',
                                    headerColor: 'orange',
                                    headerFontColor: 'white',
                                    bodyText: `Para ativar a entrega turbo é necessário que todos os itens do carrinho sejam nacionais!`,
                                    methodOK: (() => { 
                                        this.entregaTurbo = 0; 
                                    }),
                                };
                            }
                            else {

                                this.dialogMessage = {
                                    show: true,
                                    headerText: 'Atenção',
                                    headerColor: 'orange',
                                    headerFontColor: 'white',
                                    bodyText: `Ao ativar a entrega turbo, o preço dos itens aumentará seu <br />
                                               <span style="color: var(--color__red); font-weight: bold;">valor em 50%</span> e passará por aprovação da fábrica e da propria SPTech`,
                                    methodOK: (() => { }),
                                };
                            }

                        }

                        this.calcularTotal();
                    }, 500);                    
                }
            }
        },

        async created() {
            const configuracaoSistemaResult = await this.$store.dispatch("configuracaoSistemaModule/GetById", 1);
            this.configuracaoSistema = configuracaoSistemaResult.result;

            this.recarregarCarrinho();
        }
    })
</script>

<style>
    
    input[name="campoQtde"] {
        text-align: center !important;
    }

    .btnCarrinho {
        border: none !important; 
        color: var(--color__cinza) !important;
        margin-top:5px;
    }

    .btnEdit {
        border: none !important; 
        margin-left: 10px;
    }

</style>